
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";
.selling-point {
    font-size: 11px;
    color: #999;
    padding-top: 10px;
    font-style: italic;
}
.main-banner {
    display: flex;
    gap: 100px;
    // position: relative;
    align-items: start;
    justify-content: start;
    height: auto;

    min-height: calc(100vh - 58px);
    &::before {
        content: "";
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        height: 100%;
        top: 0;
        position: absolute;
        background-image: url("/assets/images/debtCornerMobileBg.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: right;
        z-index: -2;
        @include lg {
            background-image: url("/assets/images/debtCornerDesctopBg.jpg");
        }
    }

    .form {
        z-index: 5;
        // border: 1px solid;
        width: 100%;
        padding: 30px 30px;
        max-width: 700px;
        background: #fff;
        box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.25);

        min-height: 500px;

        margin-top: 20px;
        display: flex;
        flex-direction: column;
        // margin-bottom: 100px;
        position: relative;
        border-radius: 15px;
        @include lg {
            min-width: 300px;
            width: 50%;
            padding: 40px 40px;
            // margin-bottom: 200px;
            margin-top: 50px;
        }
        .step:nth-of-type(1) {
            .form-field {
                > label {
                    text-align: center;
                    font-size: 16px !important;
                    font-weight: 800;

                    @include lg {
                        font-size: 20px !important;
                        line-height: 30px;
                    }
                }
            }
            .step-title {
                color: black !important;
            }
        }
        .steps-inner {
            flex-grow: 1;
            padding-bottom: 20px;
        }

        .step-title {
            // color: #054dc2 !important;

            text-align: center;
            font-size: 22px;
            @include lg {
                font-size: 25px !important;
                line-height: 37px !important;
            }
            font-weight: 700 !important;
            margin-bottom: 10px;
        }
        .label-as-step-title {
            text-align: center;
            font-weight: 700 !important;
            margin-top: -20px;
            margin-bottom: 25px !important;
            font-size: 22px;

            @include lg {
                font-size: 25px !important;
                line-height: 37px !important;
                // margin-right: 10px;
                // margin-left: 10px;
            }
        }
        .step-description {
            text-align: center;

            margin-bottom: 2rem;

            @include lg {
                font-size: 20px;
                line-height: 30px;
            }
        }
        .fields-wrapper {
            margin-top: 1.5rem;
        }
        .form-button {
            max-width: unset !important;
            margin: 0 auto !important;
            margin-top: 5px !important;
            border-radius: 8px !important;
            background-color: #ff823c !important;
            font-weight: bold;
            transition: all 0.2s ease-in-out;
            color: #fff !important;
            @apply shadow-lg;

            svg {
                path {
                    fill: #333;
                }
            }

            &:hover {
                @apply shadow;
            }
        }
        .form-field {
            max-width: unset;
            margin: 0 auto;
            margin-bottom: 0.5rem;
            // > label {
            //     color: black !important;
            // }
        }
        .radio-field {
            display: block;
            // border: 1px solid;
            width: 100%;
            max-width: 480px;
            margin: 10px auto 0;
            & > div {
                width: 100%;
                margin-bottom: 15px;
                display: flex;
                input {
                    &:checked + label {
                        // border: 1px solid $debtreliefanalysis-main-color;
                        background-color: #ff823c;
                        color: #fff;
                        // font-weight: bold;
                        // color: #fff;
                    }
                }
                label {
                    border-radius: 8px;

                    border: 1px solid $homewarrantyestimate-border-color;
                    background-color: #fff;
                    color: #333;

                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    // text-transform: capitalize;
                    &:hover {
                        background-color: #ff823c;
                        color: #fff;
                    }
                    @include md {
                        min-height: 54px;
                    }
                    svg {
                        margin-bottom: 10px;
                        width: 30px;
                        height: auto;
                    }
                    span {
                        font-size: 16px;
                    }
                }
            }
        }
        .checkbox-field {
            & > div {
                width: 100%;
                margin-bottom: 15px;
                display: flex;
                input {
                    &:checked + label {
                        font-weight: 500;
                        background-color: #f2f8ff;
                    }
                }
                label {
                    border-radius: 8px;
                    border: 1px solid $homewarrantyestimate-main-color;
                    color: $nextautowarranty-denim-blue;
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    // text-transform: capitalize;
                    @include md {
                        min-height: 54px;
                    }
                }
                label:hover {
                    background-color: $homewarrantyestimate-hover-color;
                }
            }
        }
        .field-note {
            margin-left: 7px;
        }
        .input-field {
            border-radius: 8px;
        }
        .field-error-message {
            left: 7px;
        }
        .field {
            &:not(.radio-field) {
                background-color: white;
            }
        }
        .tcpa {
            margin-bottom: 10px;
        }
    }
    .image {
        z-index: 2;
        & > div {
            position: absolute !important;
            left: 0;
            bottom: 0;
            height: 300px;
            width: 100vw;
            max-width: unset !important;
            left: 50% !important;
            transform: translateX(-50%) !important;

            @include lg {
                height: 100%;
                top: 0 !important;
                left: 0%;
            }
        }

        img {
            min-height: unset !important;
            min-width: unset !important;
            width: auto !important;
            height: 100% !important;
            max-width: unset !important;
            margin-left: 0 !important;
            margin-right: auto !important;
        }
    }
}

// .progress-background {
//     & > div:last-child {
//         background-color: #fff;
//     }
// }
